import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactPixel from 'react-facebook-pixel';
import { loadStripe } from '@stripe/stripe-js';
import siteSetting from "../../config/env";
import {paymentActions} from "../../_actions";
const stripePromise = loadStripe(siteSetting.stripeKey);

class Redirection extends Component {
  constructor(props){
    super(props);
    this.state = {
      loader: false,
    }
    console.log(this.props , "###props value")
    let uri = this.props.location.pathname  
    let price = uri.substring(19, 20)
    let selectedPlan  = uri.substring(26, 27)
    let priceId = 'price_1JjN90Ju70D4HRltddT90qlf'
    
    if (price == 1 && selectedPlan == 1) {
      priceId = 'price_1LqJqnJu70D4HRltB1RERj9s'
    }
    //salon yearly
    else if (price == 2 && selectedPlan == 1) {
      priceId = 'price_1LqJppJu70D4HRltIogJBsqw'
    }
    // //SE monthly
    else if (price == 1 && selectedPlan == 2) {
      priceId = 'price_1LqJtKJu70D4HRltrz3kIB6T'
    }
    // //SE yearly
    else if (price == 2 && selectedPlan == 2) {
      priceId = 'price_1LqJsRJu70D4HRltWAjuVoZb'
    }


    this.goPayment(priceId, selectedPlan)
    }

    

    goPayment = async (priceId,selectedPlan)=>{
      const stripe = await stripePromise;
      let self=this;
      let subscriptionData = {
          priceId: priceId,
          userType:selectedPlan
      }
      self.props.dispatch(paymentActions.subscriptionCheckout(subscriptionData))
          .then(function (response) {
              console.log("response",response);
              if (response.data.statusCode == 0) {
                  if (response.data.error.errorCode === 10) {
                      self.setState({
                          error: response.data.error.responseMessage
                      })
                  } else {
                      self.setState({
                          error: response.data.error.responseMessage
                      })
                  }
                  self.setState({
                      isFormLoader: false
                  });
              } else {
                  self.setState({
                      isFormLoader: false
                  });
                  let sessionId=response.data.responseData.result.id;
                  stripe.redirectToCheckout({
                      sessionId: sessionId,
                  }).then(function (res){
                      console.log("stripe res=",res);
                  }).catch(function (error){
                      console.log("stripe error=",error);
                  })
              }
          })
  }

    componentDidMount(){
      // setTimeout(()=>{
      //   this.setState({loader: false})
      // },2500)
      ReactPixel.pageView(); // For tracking page view
      document.body.classList.add('login-home-temp');
 
   }

    render() {
      if(this.state.loader){
        return <div className="cm_loader"><div className="loader"></div></div>
      }
        return (
          <>      
           </>
        )

    }
}
function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Redirection);
