import React, { useEffect,useState } from 'react'
import SettingSidebar from '../settings/SettingSidebar';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { useDispatch, useSelector } from "react-redux";
import SettingInputControls from './SettingInputControls';
import { Modal, Pagination } from 'react-bootstrap';
import animationData from '../../assets/animation-data/loader.json'
import { marketingAction } from "../../_actions/marketing.actions";
import Lottie from 'react-lottie';
import {paymentService} from "../../_services";
// import toast from "react-toast-notification";
// import {loadStripe} from "@stripe/stripe-js";
// import siteSetting from "../../config/env";
// import { BackArrow } from '../SvgIcon';

// const stripePromise = loadStripe(siteSetting.stripeKey);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
  };

function MarketingSetting(props) {
    const dispatch = useDispatch();
    const userInfo = useSelector(state=>state?.user?.userInfo);
    const getSalonMarketingSetting = useSelector(state=>state?.getSalonMarketingSetting);
    const [isLoader, setIsLoader] = useState(false);
    const [isSalonOwner,setIsSalonOwner] = useState(0);
    const [salonId,setSalonId] = useState('');
    const [salonData,setSalonData] = useState([]);
    const [cardList,setCardList] = useState([]);
    const [cardPopup, setCardPopup] = useState(false);
    const [cardPrimaryToggle, setCardPrimaryToggle] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const [defaultPayment, setDefaultPayment] = useState();
    
    // const showCardPopup = (card)=>{
    //     setSelectedCard(card);
    //     if(defaultPayment===card?.id){
    //         setCardPrimaryToggle(true);
    //     }else{
    //         setCardPrimaryToggle(false);
    //     }
    //     // setCardPrimaryToggle(e?.target?.checked);
    //     setCardPopup(true)
    // }
    const HideCardPopup = ()=>{
        setCardPopup(false)
        setSelectedCard({});
    }

    useEffect(() => {
        const salonId = localStorage.getItem('salonId');
        setSalonId(salonId);
        setIsSalonOwner(userInfo?.hairdresser?.isSalonOwner);
        getMarketingSetting();
        checkpaymentMethodAdded();

        if(defaultPayment===selectedCard?.id){
            setCardPrimaryToggle(true);
        }
        
     /* eslint-disable-next-line */
    }, []);


    useEffect(()=>{
        if(cardList?.length===1){
            if(defaultPayment!==cardList[0].id){
                    cardMakePrimary();
            }
        }
     /* eslint-disable-next-line */
    },[cardList])

 
      const updateMarketingSetting = (data) => {
        const params = {
            hairdresserId:data?.hairdresserId,
            salonConsent:data?.salonConsent,
            selfEmpConsent:data?.selfEmpConsent,
            controlledSettingId:data?.controlledSettingId
        }
   
        dispatch(marketingAction.updateMarketingSetting(params)).then((res) => {

        });
      };
      
      const getMarketingSetting = () => {
        dispatch(marketingAction.getMarketingSetting()).then((res) => {
          if (res.data.statusCode === 1) {
            if (res?.data?.responseData?.result.length>0) {
              let data = res?.data?.responseData?.result;
            //   dispatch(marketingAction.setSalonMarketingSetting(data));
               setSalonData(data);
            }
            setIsLoader(false);
          }else{
            setIsLoader(false);
          }
        });
      };

    const checkpaymentMethodAdded = () => {
        let params={};
        paymentService.checkCardAdded(params)
            .then(function(res){
                if(res.data.statusCode===1) {
                    let paymentMethodId = res.data.responseData?.stripeCustomer?.invoice_settings?.default_payment_method;
                    if(paymentMethodId!==undefined){
                        setDefaultPayment(paymentMethodId)
                    }
                    
                    if(res.data.responseData?.result?.data.length>0){
                        if(res.data.responseData?.result?.data.length===1){
                            setSelectedCard(res.data.responseData?.result?.data[0]);
                        }else{
                            setSelectedCard({});
                        }
                        setCardList(res.data.responseData?.result?.data)
                    }
                }
            })
    };

    // const cardRemove = () => {
    //     if(selectedCard && (selectedCard?.id!==undefined && selectedCard?.id!=='')){
    //     let params={
    //         stripePaymentMethodId:selectedCard?.id
    //     };
    //     paymentService.cardRemove(params)
    //         .then(function(res){
    //             if(res.data.statusCode===1) {
    //                 checkpaymentMethodAdded();
    //                 HideCardPopup();
    //             }
    //         })
    //     }
    // };

    const cardMakePrimary = () => {
        if(selectedCard && (selectedCard?.id!==undefined && selectedCard?.id!=='')) {
            console.log('card list', selectedCard);
            if (cardPrimaryToggle === true) {
                let params = {
                    stripePaymentMethodId: selectedCard?.id
                };
                paymentService.cardMakePrimery(params)
                    .then(function (res) {
                        if (res.data.statusCode === 1) {
                            console.log("res", res.data.responseData?.result);
                            checkpaymentMethodAdded();
                            HideCardPopup();
                        }
                    })
            }else{
                HideCardPopup();
            }
        }
    };

    // const paymentMethodAdd = async () => {
    //     let params={};
    //     const stripe = await stripePromise;
    //     paymentService.paymentMethodAdd(params)
    //         .then(function(res){
    //             if(res.data.statusCode===1) {
    //                 let sessionId=res.data.responseData?.result.id;
    //                 if(sessionId!=="") {
    //                     stripe.redirectToCheckout({
    //                         sessionId: sessionId,
    //                     }).then(function (res) {
    //                         console.log("stripe res=", res);

    //                     }).catch(function (error) {
    //                         toast.error(error.messages);
    //                         console.log("stripe error=", error);
    //                     })
    //                 }else{
    //                     toast.error("Payment gateway error");
    //                 }
    //             }else{
    //                 //return false;
    //             }
    //         })
    // };



    // function isCardExpired(card) {
    //     let expirationDate = `${card?.card?.exp_month}/${card?.card?.exp_year}`;
    //     var parts = expirationDate.split('/');
    //     var expirationMonth = parseInt(parts[0], 10);
    //     var expirationYear = parseInt(parts[1], 10);
    //     var currentDate = new Date();
    //     var currentMonth = currentDate.getMonth() + 1; // January is 0
    //     // Check if the card has expired
    //     if (expirationYear < currentDate.getFullYear() || (expirationYear === currentDate.getFullYear() && expirationMonth < currentMonth)) {
    //         return true; 
    //     } else {
    //         return false; 
    //     }
    // }
    

  return(
    <div className="h_n">
    <div className="body_container cm_bg_white">
        <TopHeader title="Marketing" {...props}/>
        <SideNav {...props}/>
        <div className='new-setting-wrapper'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 mobile-hidden'>
                    {/*{salonData.length>0?*/}
                        <SettingSidebar
                         marketing={true}
                         isOwner={userInfo?.hairdresser?.isSalonOwner}
                         salonConsent={salonData[0]?.salonConsent}
                         selfEmpConsent={salonData[0]?.selfEmpConsent}
                        />
                        {/*:null}*/}
                    </div>
                    {isLoader?<div className='col-md-8'>
                    <div className='salon-body-main-wrp booking-urlpage'>
                        <div className='new-stng-cmn'>
                        <div className="text-center pr-md-5">
                            <Lottie options={defaultOptions}
                                    height={50}
                                    width={200}
                            />
                            <h4 className="text-center">Loading Data...</h4>
                        </div>
                        </div>
                    </div>
                </div>:
                <div className='col-md-8'>
                    <div className='salon-body-main-wrp account--password-temp new-stng-cmn __marketing '>
                        <div className='_marketing_setting'>
                        <h5>Salon controlled marketing</h5>
                        <p className='mb-4'>If both parties consent then the self-employed staff’s SMS allocation will be added to the salons allocation and salon will manage the marketing and SMS of self-employed staff that opt in. Salon has to opt-in first.
                        </p>
                        {salonData?.length>0?salonData.map((emp,i)=>(<SettingInputControls updateMarketingSetting={updateMarketingSetting} indexNum={i} key={'self_emp_'+i} data={emp} loginType={isSalonOwner} />)):null}
                        </div>
                   </div>

                   {/* <div className='salon-body-main-wrp account--password-temp new-stng-cmn __marketing '>
                     <div className='_marketing_setting _card_parent'>
                        <h5>Billing Card</h5>
                        <p className='mb-4'>This is the card that will be used for billing once your SMS allocation has been used..</p>
                        <div className='table-responsive payout_table '>
                        <table className={`table table-borderless `}>
                        <tbody>
                            {cardList.map((item)=>{
                            return(
                                <tr key={item?.id} className='_card_row'  onClick={()=>showCardPopup(item)}>
                                <td>{item?.card?.brand}</td>
                                <td>••••{item?.card?.last4}</td>
                                <td>{item?.card?.exp_month} / {item?.card?.exp_year}</td>
                                <td className='_btn_group'>
                                {isCardExpired(item)?<span className='btn btn-sm'>Expired</span>:null}
                                {defaultPayment===item?.id?<span className='btn btn-sm'>Default</span>:null}
                                </td>
                                <td className='_icon'><BackArrow/></td>
                            </tr>
                             )
                           })
                          }
                        </tbody>
                       </table>
                       </div>
                       <div className='text-right mt-4'>
                       <button className="btn btn-inf ml-auto pl-5 pr-5" onClick={()=>paymentMethodAdd()}>Add New Card</button>
                       </div>
                     </div>
                        </div> */}
                </div>}
                    
                </div>
            </div>
        </div>
                
    </div>

   {/*<Modal 
    show={cardPopup} 
    // onHide={HideCardPopup}
    centered
    className="bg_white_modal text-center card_popup_modal "
    
     blurClass="blure_bg" 
     size="lg"
    //  className='payout_modal'
     >
        <i className="fa fa-times cm_hide_mod" onClick={HideCardPopup} />
        <Modal.Body>
           <div className='card_popup text-left'>
               <div className='_card_info'>
                    <h4>{selectedCard?.card?.brand}
                        {isCardExpired(selectedCard)?<span className='ml-3'>Expired</span>:null}
                    </h4>
                    <p>{selectedCard?.card?.last4}</p>
               </div>
               <div className="_expired mt-4">
                    <p >Expiry date</p>
                    <h6>{selectedCard?.card?.exp_month} / {selectedCard?.card?.exp_year}</h6>
               </div>
 
               {cardList?.length===1?null:<div className="salon_switch_container pl-0 mt-5">
               <h6 className='mr-5'>Make default card for payments</h6>
               <label className="switch">
                 <input 
                    type="checkbox"
                    checked={cardPrimaryToggle}
                    onChange={(e)=>setCardPrimaryToggle(e?.target?.checked)}
                    disabled={cardPrimaryToggle}
                    /><span className="slider round"></span>
                </label>
               </div>}
              
               <div className='btn__group mt-5'>
                {cardList?.length>1?<button className="btn btn-disc pl-5 pr-5" onClick={(e)=>cardRemove()} >Delete</button>:null}
                {cardList?.length===1?null:<button className="btn btn-inf pl-5 pr-5" onClick={()=>cardMakePrimary()}>Save</button>}
               </div>
           </div>
        </Modal.Body>
                    </Modal> */}
</div>
)

}



export default MarketingSetting;
const cardrow=[0,0,0,0,0,0]