import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Close, FilterIcon, MenuIcon, WhiteMenuicon } from "../SvgIcon";
import {bookingActions, userActions} from "../../_actions";
const toggleMenuClass = () => {
  document.body.classList.toggle("nav_menu_open");
};

const TopHeader = (props) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState({});
  const [openDropdownNav, setOpenDropdownNav] = useState(false);
  const [bookings, setBooking] = useState(false);

  const changeStapPos = (val) => setStep(val);

  const handleClose = () => {
    setShow(false);
    setStep(1);
  };

  useEffect(() => {
    let self=this;
    if (localStorage.getItem('accessToken')) {
      // let params={}
      // self.props.dispatch(userActions.profile(params))
      //     .then(function (response) {
      //
      //     })
    }else{
      props.history.push('/')
    }
    setSelectedClient(props?.selectedClient);
  }, [props?.selectedClient]);

  useEffect(() => {
    setBooking(props?.booking);
  }, [props?.booking]);

  const handleShow = () => setShow(true);

  let { title, cross } = props;
  // console.log("cross cross cross", props)
  const gotoSalon = () => {
    props.history.push("/settings/booking-url");
    setShow(false);
    setStep(1);
  };

  const goHome = () => {
    props.history.push("/diary/" + localStorage.getItem("salonId"));
  };

  const goToPrevPage = () => {
    if (props?.previousPageUrl != undefined) {
      let stateObj = props?.location?.state;
      props.history.push({
        pathname: props?.previousPageUrl,
        state: stateObj,
      });
    } else {
      window.history.back();
    }
  };

  const openDropdown = () => {
    setOpenDropdownNav(!openDropdownNav);
  };

  // const handleEditBooking=()=>{
  //         this.setState({
  //             rescheduleRequestModal:false,
  //             editBookingModal:true
  //         })
  //     }

  const editBooking = () => {
    //console.log("editBooking",this.props)

    const stateObj = { pageLoadingStatus: 1 };
    const url = "/new-booking-edit/" + props.bookingId;
    props.history.push({
      pathname: url,
      state: stateObj,
    });
  };

  const viewInvoice = () => {
    props.history.push("/invoice/" + props.bookingId);
  };

  const checkoutBooking = () => {
    // this.props.history.push('/booking-checkout/'+this.props.bookings._id)
    if (props?.checkoutBooking != undefined) {
      props.checkoutBooking();
    } else {
      const stateObj = { pageLoadingStatus: 1 };
      const url = "/checkout/" + props.bookingId;
      props.history.push({
        pathname: url,
        state: stateObj,
      });
      // props.history.push('/checkout/'+props.bookingId)
    }
  };

  const updateEmailBookingStatus = (status) => {
    let statusVal = status ? 1 : 0;
    props.updateEmailBookingStatus(statusVal);
  };

  return (
    <div className="top_header">
      {/*{console.log("props",props)}*/}
      <span onClick={toggleMenuClass} className="menu_toogle_icon">
        {/* <img src={require('../../assets/images/menu-icon.png')} />  */}
        <MenuIcon />
      </span>
      {props?.editPageStatus ? (
        <div className="salon_switch_container _header_toggle pl-0">
          <p className="ml-0 mr-3">Notify client of booking update</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={(e) => updateEmailBookingStatus(e.target.checked)}
              checked={props?.isMailOff ? true : false}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ) : (
        ""
      )}
      <h1>{title}</h1>
      {props?.headerButtonStatus !== true ? (
        <div className="user_pic">
          {localStorage.getItem("isShow") !== "true" ? (
            <button onClick={handleShow} className="btn btn-dark online-btn">
              Online Bookings
            </button>
          ) : (
            ""
          )}
          <img
            src={
              props.user &&
              props.user.userInfo &&
              props.user.userInfo.userImage.circleImage
                ? props.user.userInfo.userImage.circleImage
                : require("../../assets/images/client-plachoder.jpg")
            }
          />
        </div>
      ) : (
        <div className="d-flex topbar_btn_group">
          {title === "View Booking" || title === "Completed Booking" ? (
            props?.noShowButtonStatus?null:<Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic-button"
                className={"more__option pr-1"}
              >
                More Options{" "}
                <img
                  src={require("../../assets/images/down-arrow-icon1.png")}
                  className={`toggle_arrow ${openDropdownNav ? "up" : "down"}`}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="more__option_menu">
                {props?.bookingStatus === 4 ? (
                  <Dropdown.Item onClick={viewInvoice}>
                    {" "}
                    <span >View Invoice </span>
                  </Dropdown.Item>
                ) : (
                  
                  <>
                    {bookings.bookingStatus !== 5 &&
                    bookings.bookingStatus !== "5" ? (
                      <>
                        <Dropdown.Item onClick={() => props?.showModalHendler("editBookingModal")}>
                          <span

                          >
                            Edit Booking
                          </span>
                        </Dropdown.Item>
                        {/*{selectedClient?._id==='64f5c7789a069cda0188fea3'?null:<Dropdown.Item ><span onClick={()=>props?.showModalHendler('rescheduleRequestModal')} >Reschedule Booking</span></Dropdown.Item>}*/}
                      </>
                    ) : null}
                    <Dropdown.Item onClick={() => props?.showModalHendler("cancelBookingModal")}>
                      <span
                        className="text-danger"

                      >
                        Cancel Booking
                      </span>
                    </Dropdown.Item>
                    {selectedClient?._id ===
                    "64f5c7789a069cda0188fea3" ? null : bookings.bookingStatus ===
                        1 ||
                      bookings.bookingStatus === "1" ||
                      bookings.bookingStatus === 0 ||
                      bookings.bookingStatus === "0" ? (
                      <Dropdown.Item onClick={() => props?.showModalHendler("noShowModal")}>
                        <span
                          className="text-danger"

                        >
                          No Show
                        </span>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          {props?.deleteReminderButton?<button
            className={props?.type==='reminder'?"btn btn-danger ml-3 mr-3 pl-4 pr-4":"btn btn-danger ml-3 mr-3"}
            onClick={() => props?.deleteReminder()}
          >
            Delete
          </button>:null} 

          {props?.cancelButton ? (
            <button className="btn btn-white" onClick={() => goToPrevPage()}>
              Cancel
            </button>
          ) : null}

          {props?.cancelRefreshPage ? (
            <button className="btn btn-white" onClick={() => props?.resetAllSetting()}>
              Cancel
            </button>
          ) : null}


          {props?.saveReminderButton && props?.isLoadingStatus ? (
            <button
              className={props?.type==='reminder'?"btn btn-dark-blue ml-3 pl-4 pr-4":"btn btn-dark-blue ml-3"}
              onClick={() => props?.saveReminder()}
            >
              Save
            </button>
          ) : props?.saveReminderButton===undefined?null:(
            <button  className={props?.type==='reminder'?"btn btn-dark-blue ml-3 pl-4 pr-4":"btn btn-dark-blue ml-3"} disabled={true}>
              Save
            </button>
          )}

          {props?.saveBookingButton ? (
            <button
              className="btn btn-dark-blue ml-3"
              disabled={props?.isbookingSubmit === true ? true : false}
              onClick={() => props?.saveBooking()}
            >
              Save Booking
            </button>
          ) : null}

          {props?.saveCancellationButtonStatus ? (
            <button
              className="btn btn-dark-blue ml-3"
              disabled={props?.isPolicySubmit}
              onClick={() => props?.saveCancellationPolicy()}
            >
              Save 
            </button>
          ) : null}
          
          {props?.checkoutButton && props?.bookingStatus!=4 && props?.bookingStatus!=3 && props?.bookingStatus!=6 ?(
            <button
              className="btn btn-dark ml-3"
              disabled={props?.isbookingSubmit}
              onClick={checkoutBooking}
            >
              Checkout
            </button>
          ):null}
        </div>
      )}

      
      {props?.cancelButton ? (
        <span
          className="close-icon pointer-event cancel__mobile"
          onClick={() => goToPrevPage()}
        >
          <Close />
        </span>
      ) : null}
      {/* //show the cancel button only mobile */}

      {cross == "true" ? (
        <span className="close-icon pointer-event" onClick={goHome}>
          <Close />
        </span>
      ) : (
        ""
      )}

      <Modal show={show} onHide={handleClose} className="bg_white_modal">
        <i className="fa fa-times cm_hide_mod" onClick={handleClose} />
        <Modal.Body>
          {step == 1 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-1.png")} />
              </div>
              <h2>Online Bookings</h2>
              <h3>
                Start taking bookings through your website and social media.
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 2 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-2.png")} />
              </div>
              <h2>Get Discovered</h2>
              <h3>
                Get discovered by local clients through the Beu marketplace and
                rank higher in Google search results
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 3 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-3.png")} />
              </div>
              <h2>Custom URL Link</h2>
              <h3>
                Simply add your URL to whatever platform you want to take online
                bookings with
              </h3>
            </div>
          ) : (
            ""
          )}

          {step == 4 ? (
            <div className="stap_wrapper">
              <div className="_img_wrap">
                <img src={require("../../assets/images/login-step-4.png")} />
              </div>
              <h2>Enable Online Bookings</h2>
              <h3>
                Make sure you have added a salon image, salon address and bank
                account if you want to take online payments
              </h3>
            </div>
          ) : (
            ""
          )}

          <ul className="stap-indicate">
            <li
              onClick={() => changeStapPos(1)}
              className={`${step == 1 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(2)}
              className={`${step == 2 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(3)}
              className={`${step == 3 ? "active" : ""}`}
            />
            <li
              onClick={() => changeStapPos(4)}
              className={`${step == 4 ? "active" : ""}`}
            />
          </ul>

          <div className="stap-action">
            {step == 4 ? (
              <React.Fragment>
                <button
                  className="btn btn-outline-dark mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button className="btn btn-dark" onClick={gotoSalon}>
                  Confirm
                </button>
              </React.Fragment>
            ) : (
              <button
                className="btn btn-dark"
                onClick={() => setStep(step + 1)}
              >
                Continue
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default TopHeader;
